import React from "react"
import { Link } from 'gatsby'
import Div100vh from 'react-div-100vh'
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import * as font from "../../fonts/fonts.module.scss"
import icon from "../../images/icon.svg"
import scrollDown from "../../images/scroll-down.svg"

const Services = ({data}) => {

  return(
    <StyledServices id="services">
      <GatsbyImage image={data.img.childImageSharp.gatsbyImageData} alt={"Logo"} className="services-img"/>

      <section className="services-content">
        <h1 className={font.lB + " services-title"}>{data.title}</h1>
        <hr/>

        <div className="content-grid">

          <div className="grid-column">
            <h3 className={font.lSB + " category-title"}>
              <img src={icon} alt="Logo" className="cat-title-img"/>
              {data.categories.fisio.title}
            </h3>
            <h3 className="list">
              {data.categories.fisio.subs.map((data, i)=>(
                <p className={font.lM + " category"} key={"categoria:" + i}>{data}</p>
              ))}
            </h3>
          </div>

          <div className="grid-column grid-right">
            <h3 className={font.lSB + " category-title"}>
              <img src={icon} alt="Logo" className="cat-title-img"/>
              {data.categories.psico.title}
            </h3>
            <h3 className="list">
              {data.categories.psico.subs.map((data, i)=>(
                <p className={font.lM + " category"} key={"categoria:" + i}>{data}</p>
              ))}
            </h3>

            <h3 className={font.lSB + " category-title"}>
              <img src={icon} alt="Logo" className="cat-title-img"/>
              {data.categories.psique.title}
            </h3>

            <h3 className={font.lSB + " category-title"}>
              <img src={icon} alt="Logo" className="cat-title-img"/>
              {data.categories.nutri.title}
            </h3>
          </div>

        </div>

      </section>

      <Link to="/#map" className="scroll-down">
        <img src={scrollDown} alt="Ver mais" className="scroll-down animated bounce"/>
      </Link>
    </StyledServices>
  )
}

export default Services

const StyledServices = styled(Div100vh)`
  position: relative;
  width: 100%;
  overflow: hidden;
  .services-img{
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
  .services-content{
    position: relative;
    z-index: 2;
    width: clamp(600px, 50%, 1200px);
    @media only screen and (max-width: 768px){
      width: 100%;
    }
    margin: 0 auto;
    color: #ffffff;
    top: 50%;
    transform: translateY(-50%);
    .services-title{text-align: center;}
    hr{
      border: 2px solid #01938A;
      width: 50px;
    }
    .content-grid{
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 2vw;
      grid-row-gap: 4vw;
      margin-top: 6vw;
      @media only screen and (max-width: 768px){
        justify-items: center;
        margin-top: 10vw;
      }
      @media only screen and (max-width: 540px){
        grid-template-columns: 1fr;
      }
      .grid-column{
        .category-title{
          display: flex;
          align-items: center;
          text-transform: uppercase;
          margin-bottom: 2em;
          @media only screen and (max-width: 540px){
            margin-bottom: 1.5em;
          }
          height: 2em;
          width: 13.5em;
          .cat-title-img{
            height: 2.2em;
            margin-right: 1em;
          }
        }
        .list{
          margin-left: 3.2em;
          margin-bottom: 1.5em;
          .category{
            margin-top: 0.3em;
          }
        }
      }
      .grid-right{
        justify-self: right;
        @media only screen and (max-width: 768px){
          justify-self: center;
        }
      }
    }
  }

  .scroll-down{
    position: absolute;
    bottom: 1vw;
    left: 50%;
    transform: translateX(-50%);
    width: clamp(15px, 1.2vw, 25px);
    img{width: 100%;}
    z-index: 1;
  }


  .animated {
    -webkit-animation-duration: .5s;
    animation-duration: .5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
  }
  @-webkit-keyframes bounce {
    0%, 100% {
      -webkit-transform: translateY(0);
    }
    50% {
      -webkit-transform: translateY(-5px);
    }
  }
  @keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-5px);
    }
  }
  .bounce {
    -webkit-animation-name: bounce;
    animation-name: bounce;
  }
  #animated-example {
    width: 20px;
    height: 20px;
    background-color: red;
    position: relative;
    top: 100px;
    left: 100px;
    border-radius: 50%;
  }
`