import React from "react"
import styled from "styled-components"
import * as font from "../../fonts/fonts.module.scss"

const Map = ({data}) => {

  return(
    <StyledMap id="map">
      <div className="contact">
        <div className="content">
          <h3 className={font.lB + " title"}>{data.title[0]}</h3>
          <h4 className={font.lR + " text"}>{data.title[1]}</h4>
        </div>
      </div>
      <div className="map">
        <iframe width="100%" height="100%" title="gmap_canvas" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3028.6116373114733!2d-8.656237384289657!3d40.61639145139544!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd23a350e404daad%3A0xe35ff29a9f380474!2sSa%C3%BAde%20Positiva!5e0!3m2!1spt-PT!2spt!4v1620572956420!5m2!1spt-PT!2spt" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" allowfullscreen="" loading="lazy"/>
      </div>
    </StyledMap>
  )
}

export default Map

const StyledMap = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  padding: 5vw 0;
  @media only screen and (max-width: 1000px){
    height: unset;
    flex-direction: column;
  }
  .contact{
    width: 50%;
    @media only screen and (max-width: 1000px){
      width: 100%;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .content{
      width: clamp(300px, 80%, 800px);
      display: flex;
      flex-direction: column;
      align-items: center;
      @media only screen and (max-width: 1000px){
        padding: 40px 0;
      }
      .title{
        color: #00AF9A;
        text-align: center;
      }
      .text{
        color: #003B71;
        margin-bottom: 2em;
        text-align: center;
      }
      .btns{
        margin-top: 2em;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1em;
        @media only screen and (max-width: 1000px){
          grid-gap: 6vw;
        }
        .btnLinks{
          background-color: #00AF9A;
          height: 3em;
          width: 8em;
          margin-top: 0.4em;
          position: relative;
          border-radius: 20px;
          img{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 2em;
            max-height: 2em;
          }
        }
      }
    }
  }
  .map{
    position: relative;
    width: 70%;
    height: clamp(300px, 45vw, 700px);
    margin: 0 auto;
    @media only screen and (max-width: 1000px){
      width: clamp(320px, 100%, 700px);
    }
  }
`