import React from "react"
import { Link } from 'gatsby'
import Div100vh from 'react-div-100vh'
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import scrollDown from "../../images/scroll-down.svg"

const Hero = ({data}) => {

  return(
    <StyledHero>
      <GatsbyImage image={data.img.childImageSharp.gatsbyImageData} alt={"Logo"} className="hero-img"/>
      <Link to="/#services" className="scroll-down">
        <img src={scrollDown} alt="Ver mais" className="scroll-down animated bounce"/>
      </Link>
    </StyledHero>
  )
}

export default Hero

const StyledHero = styled(Div100vh)`
  position: relative;
  width: 100%;
  .hero-img{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 22vw;
    min-width: 200px;
  }
  .scroll-down{
    position: absolute;
    bottom: 1vw;
    left: 50%;
    transform: translateX(-50%);
    width: clamp(15px, 1.2vw, 25px);
    img{width: 100%;}
  }


  .animated {
    -webkit-animation-duration: .5s;
    animation-duration: .5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
  }
  @-webkit-keyframes bounce {
    0%, 100% {
      -webkit-transform: translateY(0);
    }
    50% {
      -webkit-transform: translateY(-5px);
    }
  }
  @keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-5px);
    }
  }
  .bounce {
    -webkit-animation-name: bounce;
    animation-name: bounce;
  }
  #animated-example {
    width: 20px;
    height: 20px;
    background-color: red;
    position: relative;
    top: 100px;
    left: 100px;
    border-radius: 50%;
  }
`