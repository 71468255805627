import React from "react"
import styled from "styled-components"
import * as font from "../../fonts/fonts.module.scss"
import phone from "../../images/phone.svg"
import mail from "../../images/mail.svg"
import face from "../../images/face.svg"
import insta from "../../images/insta.svg"
import whats from "../../images/whats.svg"

const Footer = ({data}) => {

  return(
    <StyledFooter>
      <div className="mobile-flex">

        <div className="flex-column">
          <p className={font.lB + " title"}>{data.contact.horario.title}</p>
          {data.contact.horario.links.map((data, i)=>(
            <div className={font.lR + " links"} key={"footerLink:" + i}>
              <p>{data[0]}</p>
              <p>{data[1]}</p>
            </div>
          ))}
        </div>

        <div className="flex-column">
          <p className={font.lB + " title"}>{data.contact.marcacao.title}</p>
          {data.contact.marcacao.links.map((data, i)=>(
            <div className={font.lR + " links"} key={"footerLink:" + i}>
              <a href={data[1]} className="social" target={i===1 && "_blank"} rel={i===1 && "noreferrer"}>
                <img src={i===0 ? phone : i===1 ? whats : mail} alt={i===0 ? "Telefone" : i===1 ? "Whatsapp" : "Email"}/>
                {data[0]}
              </a>
            </div>
          ))}
        </div>

        {/* <div className={font.lR + " flex-column"}>
          <p className="spacing"/>
          <div className={font.lR + " links flex"}>
            <a href={data.socials.instaLink} className="social" target="_blank" rel="noreferrer">
              <img src={insta} alt="Instagram"/>
              {data.socials.insta}
            </a>
            <a href={data.socials.faceLink} className="social" target="_blank" rel="noreferrer">
              <img src={face} alt="Facebook"/>
              {data.socials.face}
            </a>
          </div>
        </div> */}
      </div>
    </StyledFooter>
  )
}

export default Footer

const StyledFooter = styled.footer`
  position: relative;
  width: 100%;
  background-color: #003B71;
  .mobile-flex{
    display: flex;
    justify-content: space-around;
    color: #ffffff;
    padding: 3vw 0;
    @media only screen and (max-width: 650px){
      flex-direction: column;
      width: fit-content;
      margin: 0 auto;
      .flex-column{
        margin: 1em 0;
      }
    }
  }
  .flex-column{
    .title{
      text-transform: uppercase;
    }
    .links{
      margin-top: 2em;
      @media only screen and (max-width: 1100px){
        margin-top: 1em;
      }
    }
    .flex{
      display: flex;
    }
    .social{
      margin-bottom: 1em;
      display: flex;
      align-items: center;
      img{height: 1.5em; margin-right: 1em;}
    }
    .spacing{
      height: 1em;
      @media only screen and (max-width: 650px){
        height: 0;
      }
    }
  }
`