import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"
import Hero from "../components/hero/hero"
import Services from "../components/services/services"
import Map from "../components/map/map"
import Footer from "../components/footer/footer"

const IndexPage = ({data}) => (
  <Layout>
    <SEO title="Saúde Positiva"/>
    <Hero data={data.homeJson.hero}/>
    <Services data={data.homeJson.services}/>
    <Map data={data.homeJson.map}/>
    <Footer data={data.globalJson.footer}/>
  </Layout>
)

export default IndexPage

export const Json = graphql`
  query home {
    homeJson{
      hero{
        img{
          childImageSharp{
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
              width: 3840
            )
          }
        }
      }
      services{
        img{
          childImageSharp{
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, JPG]
              width: 3840
            )
          }
        }
        title
        categories{
          fisio{
            title
            subs
          }
          psico{
            title
            subs
          }
          psique{
            title
          }
          nutri{
            title
          }
        }
      }
      map{
        title
      }
    }
    globalJson{
      footer{
        contact{
          horario{
            title
            links
          }
          marcacao{
            title
            links
          }
        }
        socials{
          insta
          instaLink
          face
          faceLink
        }
      }
    }
  }
`